<template>
  <vx-card style="padding-left: 10px">
    <vs-row vs-align="flex-start" vs-type="flex" vs-justify="left" vs-w="12">
      <vs-col vs-type="flex" vs-justify="left">
        <div class="w-full m-5">
          <h3>{{ userDetail.firstName +" "+ userDetail.lastName+ " (" + userDetail.email + ")" }}</h3>
        </div>
      </vs-col>
    </vs-row>
    <vs-row vs-align="flex-start" vs-type="flex" vs-justify="left" vs-w="12">
      <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
        <div class="w-full m-5">
          <label for="firstName">New Password</label>
          <vs-input
            v-validate="'required|min:6|verify_password|max:200'"
            data-vv-validate-on="blur"
            name="password"
            icon-no-border
            ref="password"
            icon="icon icon-user"
            icon-pack="feather"
            v-model="password"
            type="password"
            class="w-full"
          />
          <span class="error">{{ errors.first("password") }}</span>
        </div>
      </vs-col>
      <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
        <div class="w-full m-5">
          <label for="lastName">Confirm New Password</label>
          <vs-input
            v-validate="'required|min:6|max:200|confirmed:password'"
            data-vv-validate-on="blur"
            name="confirmPassword"
                    data-vv-as="password"
            icon-no-border
            icon="icon icon-user"
            icon-pack="feather"
            v-model="confirmPassword"
            type="password"
            class="w-full"
          />
          <span class="error">{{ errors.first("confirmPassword") }}</span>
        </div>
      </vs-col>

      <vs-row class="flex flex-wrap items-center p-6">
        <vs-button
          class="mr-6"
          color="danger"
          type="flat"
          @click="$router.replace('/admin/admin-user-management')"
          >Cancel</vs-button
        >
        <vs-button
          color="primary"
          v-if="userDetail._id"
          @click="onChangePassword"
          >Save</vs-button
        >
      </vs-row>
    </vs-row>
  </vx-card>
</template>

<script>
import { mapActions } from "vuex";
import moment from "moment";
import { Validator } from "vee-validate";
import {adminMixin} from "../../../mixins/adminMixin"

export default {
  name: "ChangePassword",
  mixins: [ adminMixin ],
  data: () => ({
    password: "",
    confirmPassword: "",
    userDetail: {},
  }),
  methods: {
    getUserDetail(id) {
      let self = this;
      this.getAdminInfo(id).then((res) => {
        this.userDetail = res.adminInfo;
      });
    },
    onChangePassword() {
      this.$validator.validateAll().then((success) => {
        if (!success) {
          return false;
        }
        // let data = {
        //   _id: this.userDetail._id,
        //   password: this.password,
        // };

        this.resetOtherAdminPassword(this.userDetail._id, this.password).then((res) => {
          this.$vs.notify({
            title: "Password changed",
            text: res.message.message,
            iconPack: "feather",
            icon: "icon-alert-circle",
            color: "success",
          });
          this.$router.push("/admin/admin-user-management");
        });
      });
    },
  },
  created() {
    this.$validator.extend("verify_password", {
      getMessage: (field) =>
        "Password must contain at least one uppercase, lowercase, digit and 8 characters long",
      validate: (value) => {
        var strongRegex = new RegExp(
          /^(?=.*[A-Z])(?=.*\d)(?=.*[a-z])[A-Za-z\d@$!%*#?&]{8,}$/
        );
        return strongRegex.test(value);
      },
    });
    let userId = this.$route.params.id;
    if (userId) {
      this.getUserDetail(userId);
    } else {
      this.userDetail = {};
    }
  },
};
</script>
<style>
.ql-editor {
  min-height: 200px;
}
.error {
  color: red;
}
</style>